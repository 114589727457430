import React, { Fragment, useLayoutEffect, useState } from "react";
import NavHeader from "../NavHeader";
import SideBar from "../SideBar";
import Header from "../MainHeader";
import { useLocation } from "react-router-dom";
import { AdminRoutes, PageTitles } from "../../../utils/Constants";

const Nav = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState({ tab: "Dashboard", subTab: "" });

  useLayoutEffect(() => {
    const title = `${
      location?.pathname === AdminRoutes.ORGANISATION_DASHBOARD &&
      location?.state?.name
        ? location?.state?.name + " "
        : ""
    }${PageTitles[location?.pathname]}`;
    setActiveTab({ tab: title, subTab: title });
  }, [location?.pathname, location?.state?.name]);
  return (
    <Fragment>
      <NavHeader activeTab={activeTab.tab} />
      <Header activeTab={activeTab.tab} />
      <SideBar activeTab={activeTab.tab} activeSubTab={activeTab.subTab} />
    </Fragment>
  );
};

export default Nav;
