import instance from "../../../config/axios";
import { serverUrl } from "../../../config";
import { getBearerToken, ParseError } from "../../../config/utils";
import { isArray } from "lodash";

export const getAllUsers = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(serverUrl + `/admin/users/all`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          status: 200,
          message: "Users fetched successfully",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getUsers = async (
  page = 1,
  offset = 10,
  searchQuery = "",
  suspended,
  sort,
  organizationId
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/users?page=${page}&limit=${offset}&query=${searchQuery}${suspended ? `&suspended=${suspended}` : ""}${organizationId ? `&organizationId=${organizationId}` : ""}${sort?.name && sort?.sort ? `&sortColumn=${sort?.name}&sortType=${sort?.sort}` : ""}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          status: 200,
          message: "Users fetched successfully.",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getCurrentBalanceUsers = async (
  page = 1,
  offset = 10,
  searchQuery = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/users/currentbalance?page=${page}&offset=${offset}&search=${searchQuery}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          status: 200,
          message: "Users fetched successfully.",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getUser = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(serverUrl + `/admin/users/${id}`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: "User fetched successfully.",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const createUser = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .post(serverUrl + "/admin/users/", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 201) {
        response = response.data;
        return {
          ...responseData,
          status: 201,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const updateUser = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/${data.id}`, data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 201,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const suspendRecoverUser = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + "/admin/users/manage-suspension/", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const deleteUser = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .delete(serverUrl + `/admin/users?id=${id}`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const verifyEddStatus = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/verify-edd-status/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const resetBank = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/resetBank/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const resetPassword = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/reset-password-mail/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getUserWithdraws = async (
  id,
  page = 1,
  offset = 10,
  searchQuery = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/users/withdrawals/${id}?page=${page}&offset=${offset}&searched=${searchQuery}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          pendingCount: response.pendingCount,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getUserDeposits = async (
  id,
  page = 1,
  offset = 10,
  searchQuery = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/users/deposits/${id}?page=${page}&offset=${offset}&searched=${searchQuery}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const createUserBankAccount = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .post(serverUrl + `/admin/users/create-bank-account/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 201) {
        response = response.data;
        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const sendVerificationMail = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .post(serverUrl + `/admin/users/send-verification-mail/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const fetchingLogs = async (
  id,
  page = 1,
  offset = 10,
  searchQuery = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/userLogs?page=${page}&offset=${offset}&searchQuery=${searchQuery}&userId=${id}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.total || 0,
          status: 200,
          message: "Users Logs fetched successfully.",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetUserActivitiesById = async (
  userId,
  page = 1,
  offset = 10,
  search = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/users/activities/${userId}?page=${page}&offset=${offset}&searchQuery=${search}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.total,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const updateUserKycStatus = async (data, id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/kycStatusChange/${id}`, data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          data: response.data || null,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const resetTwoFaOfUser = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/2fa-reset/${id}`, {}, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getUserCurrentBalance = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();
  return instance
    .get(serverUrl + `/admin/users/currentbalance/${id}`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          message: response.message || "",
          data: isArray(response.data) ? response.data : [],
          total: response.count || 0,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const updateUserBalance = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/users/balance/${data.userId}`, data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 201,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
