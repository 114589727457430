import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllOrganisations } from "../../services/admin/organisations";
import { getLocalStorageItem } from "../../config/AuthSetting";
import { ROLES } from "../../utils/Constants";

// Action creator for fetching organisations
export const fetchOrganisations = createAsyncThunk(
  "organisations/fetchOrganisations",
  async () => {
    const role = getLocalStorageItem("userInfo")?.role;
    if ([ROLES.SUPER, ROLES.SUPERVISOR].includes(role)) {
      const response = await GetAllOrganisations();
      return {
        state: response?.status,
        data: response?.data || [],
      };
    } else {
      return {
        state: "",
        data: [],
      };
    }
  }
);

// Define initial state
const initialState = {
  data: null,
  loading: false,
  message: "",
  error: null,
};

// Create organisations slice
const organisationsSlice = createSlice({
  name: "organisations",
  initialState,
  reducers: {
    setOrganisations(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchOrganisations
    builder
      .addCase(fetchOrganisations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        const response = action.payload;
        state.data = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setOrganisations } = organisationsSlice.actions;

export default organisationsSlice.reducer;
